
import { defineComponent, ref, onMounted, computed } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import FilterDropdwon from "./FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiIntegration } from "@/core/api";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import mixin from "@/mixins";
import {
  commonChangeFilterAddDate,
  commonExportFile,
  formatUtcDate,
  formatUtcRangeDate,
  setModuleBCN,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";
import { FilterOption, SortOption } from "@/core/directive/interface/common";

interface Invoice {
  created_at: string;
  data_interval: string;
  type: string;
  currency: string;
  downloadDisabled: boolean;
}

export default defineComponent({
  name: "api-integration-datev-balance",
  components: {
    MBDatatable,
    FilterDropdwon,
  },
  setup() {
    const { t } = useI18n();
    const { showServerErrorMsg } = mixin();
    const route = useRoute();

    const loading = ref(true);
    const tableData = ref<Array<Invoice>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const disabledExportSelected = ref(false);
    const disabledExportAll = ref(false);
    const checkedInvoice = ref<Array<number>>([]);
    const sortOptions = ref<Array<SortOption>>([
      { field: "report_date", direction: "desc" },
    ]);
    const filterOptions = ref<Array<FilterOption>>([
      { field: "status", value: 10 },
    ]);
    const tableHeader = ref([
      {
        name: t("documents.type"),
        key: "type",
        sortable: false,
      },
      {
        name: t("documents.dataRange"),
        key: "data_interval",
        sortable: false,
      },
      {
        name: t("datevBalance.reportKw"),
        key: "report_kw",
        sortable: false,
      },
      {
        name: t("datevBalance.reportDate"),
        key: "document_time",
        sortable: true,
      },
      {
        name: t("common.action"),
        key: "actions",
        sortable: false,
      },
    ]);

    const options = ref({
      filter_date: "document_time",
    });

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      updateList();
    });

    const setCommonFilter = computed(() => {
      return {
        page: currentPage.value,
        page_size: pageSize.value,
        search_value: search.value,
        sort_orders: sortOptions.value,
        filter_group: filterOptions.value,
      };
    });

    const getDatevReportList = async () => {
      loading.value = true;
      const { data } = await ApiIntegration.getDatevReportList(
        setCommonFilter.value
      );
      loading.value = false;
      if (data.code == 0) {
        tableData.value.splice(0, tableData.value.length, ...data.data.items);
        tableData.value.forEach((item) => {
          item.downloadDisabled = false;
        });
        total.value = data.data.total;
      }
    };

    const onRowsSelectChange = (selection) => {
      let ids: number[] = [];
      selection.forEach((item) => {
        ids.push(item.id);
      });
      checkedInvoice.value = ids;
    };

    const filterChangeStatus = (type, flag = true) => {
      let params = {};
      if (type === "all") {
        if (flag) {
          disabledExportAll.value = true;
          params = {
            ...setCommonFilter.value,
            id: [],
          };
        } else {
          disabledExportAll.value = false;
        }
      } else if (type === "selected") {
        if (flag) {
          if (checkedInvoice.value.length > 0) {
            params = {
              ...setCommonFilter.value,
              id: checkedInvoice.value,
            };
            disabledExportSelected.value = true;
          } else {
            return false;
          }
        } else {
          disabledExportSelected.value = false;
        }
      }
      //  else if (type === "lastWeek") {
      //   if (flag) {
      //     disabledExportLastWeek.value = true;
      //     params = {
      //       ...lastWeekFilter.value,
      //       id: [],
      //     };
      //   } else {
      //     disabledExportLastWeek.value = false;
      //   }
      // } else if (type === "lastMonth") {
      //   if (flag) {
      //     disabledExportLastMonth.value = true;
      //     params = {
      //       ...lastMonthFilter.value,
      //       id: [],
      //     };
      //   } else {
      //     disabledExportLastMonth.value = false;
      //   }
      // }
      if (flag) {
        return params;
      }
    };

    const filesExport = async (type = "all") => {
      let idParams = filterChangeStatus(type);
      const data = await ApiIntegration.exportDatevReport({
        ...idParams,
      });
      filterChangeStatus(type, false);
      commonExportFile(data);
    };

    const fileExport = async (id, item) => {
      item.downloadDisabled = true;
      const data = await ApiIntegration.exportDatevReport({
        id: [id],
      });
      item.downloadDisabled = false;
      commonExportFile(data);
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != "" && val[item] != null) {
          if (val[item] != "" && val[item] != null) {
            if (item.includes(options.value.filter_date)) {
              const isStart = item.includes("_start") ? true : false,
                date = isStart
                  ? commonChangeFilterAddDate(val[item])
                  : commonChangeFilterAddDate(val[item], 1);
              filterArr.push({
                field: options.value.filter_date,
                value: date,
                condition: isStart ? "gteq" : "lt",
              });
            } else {
              filterArr.push({
                field: item,
                value: val[item],
                condition: "eq",
              });
            }
          }
        }
      }
      filterOptions.value.splice(1, filterOptions.value.length, ...filterArr);
      updateList();
    };

    const handleFilterReset = () => {
      resetFilter();
      updateList();
    };

    const resetFilter = () => {
      filterOptions.value.splice(1, filterOptions.value.length);
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      updateList();
    };

    const updateList = () => {
      getDatevReportList();
    };

    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      updateList();
    };

    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      updateList();
    };

    const debounceSearch = _.debounce(updateList, 1000);

    const searchItems = () => {
      debounceSearch();
    };

    const removeSearch = () => {
      search.value = "";
      updateList();
    };

    return {
      formatUtcDate,
      formatUtcRangeDate,
      t,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      disabledExportAll,
      disabledExportSelected,
      checkedInvoice,
      getDatevReportList,
      onCurrentPageChange,
      onRowsPerPageChange,
      searchItems,
      removeSearch,
      onColumnSort,
      handleFilter,
      handleFilterReset,
      onRowsSelectChange,
      filesExport,
      fileExport,
    };
  },
});
