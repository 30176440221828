
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { ApiIntegration } from "@/core/api";

export default defineComponent({
  name: "document-center-invoice-dropdown",
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);

    const formData = ref({
      report_type: "",
      document_time_start: "",
      document_time_end: "",
    });

    const options = ref({
      typeLoading: false,
      typeOptions: [] as any[],
      yearLoading: false,
      kwOptions: [] as any[],
      yearOptions: [] as any[],
    });

    const getDatevReportOption = async () => {
      options.value.typeLoading = true;
      const { data } = await ApiIntegration.getDatevReportOption({});
      options.value.typeLoading = false;
      if (data.code == 0) {
        options.value.typeOptions = data.data;
      }
    };

    const getDatevReportYearOption = async () => {
      options.value.yearLoading = true;
      const { data } = await ApiIntegration.getDatevReportYearOption({});
      options.value.yearLoading = false;
      if (data.code == 0) {
        options.value.kwOptions = data.data.kw;
        options.value.yearOptions = data.data.year;
      }
    };

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    onMounted(() => {
      getDatevReportOption();
      getDatevReportYearOption();
    });

    return {
      t,
      formData,
      formRef,
      options,
      submit,
      handleReset,
    };
  },
});
